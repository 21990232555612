import React from "react";
import { motion } from "framer-motion";
import { MDBContainer, MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { LinearProgress } from "@mui/material";
import t from "./transitions";
import Spinner from "./Spinner";

class Tab extends React.Component {
  constructor(props) {
    super();
    this.status = props.status;
  }

  getIcon = (status) => {
    switch (status) {
      case "inProgress":
        if (this.props.complete)
          return <MDBIcon className="text-danger me-2" fas icon="times" />;
        return <Spinner size="sm" color="primary" className="me-2" />;
      case "complete":
        return (
          <MDBIcon className="text-success me-2" fas icon="check-circle" />
        );
      case "failure":
        return <MDBIcon className="text-danger me-2" fas icon="times" />;
      default:
        return (
          <MDBIcon className="text-primary me-2" fas icon="question-circle" />
        );
    }
  };

  render() {
    let {
      setStatus,
      details,
      setDetails,
      complete,
      setComplete,
      logView,
      setLogView,
      activeTab,
      socket,
      logs,
    } = this.props;

    switch (this.status) {
      case "ready":
        return (
          <motion.div
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
            transition={t.transition}
          >
            <MDBBtn
              color="success"
              onClick={() => {
                setStatus("starting");
                logs = [];
                socket.emit(
                  "start-tests",
                  activeTab.url?.split("version-")[1]?.split("?")[0] || "test"
                );
              }}
              className="d-block mx-auto"
            >
              <MDBIcon fas icon="paper-plane" className="me-2" />
              Start Tests
            </MDBBtn>
          </motion.div>
        );
      case "starting":
        return (
          <motion.section
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
            transition={t.transition}
          >
            <h5 className="text-center mb-3">Initializing</h5>
            <LinearProgress />
          </motion.section>
        );
      case "running":
        return (
          <motion.div
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
            transition={t.transition}
          >
            <h5 className="text-center m-0">
              {complete ? (
                details.find(
                  (detail) =>
                    detail.status === "inProgress" ||
                    detail.results?.find((result) => !result.success)
                ) ? (
                  <>
                    <MDBIcon fas icon="times" className="text-danger me-2" />
                    Tests Failed
                  </>
                ) : (
                  <>
                    <MDBIcon
                      fas
                      icon="check-circle"
                      className="text-success me-2"
                    />
                    Tests Succeeded
                  </>
                )
              ) : (
                "Running Tests"
              )}
            </h5>

            <hr />
            {details.map((detail) => (
              <motion.div
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out_scale_1}
                transition={t.transition}
              >
                <div className="d-flex align-items-center">
                  {this.getIcon(detail.status)}
                  {detail.text}
                </div>

                {detail.results?.map((result) => (
                  <div className="d-flex align-items-center ps-4">
                    <MDBIcon
                      size="sm"
                      className={
                        "me-2 text-" + (result.success ? "success" : "danger")
                      }
                      fas
                      icon={result.success ? "check-circle" : "times"}
                    />
                    {result.text}
                  </div>
                ))}
              </motion.div>
            ))}
            {complete ? (
              <motion.div
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out_scale_1}
                transition={t.transition}
              >
                <MDBBtn
                  color="success"
                  onClick={() => {
                    setComplete(false);
                    setDetails([]);
                    logs = [];
                    socket.emit(
                      "start-tests",
                      activeTab.url?.split("version-")[1]?.split("?")[0] ||
                        "test"
                    );
                  }}
                  className="d-block mx-auto mt-4"
                >
                  <MDBIcon fas icon="undo-alt" className="me-2" />
                  Re-run tests
                </MDBBtn>
              </motion.div>
            ) : (
              <></>
            )}
          </motion.div>
        );
      default:
        return <></>;
    }
  }
}

export default Tab;
