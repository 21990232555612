import React, { useEffect, useState } from "react";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { io } from "socket.io-client";
import "mdb-react-ui-kit/dist/css/mdb.dark.min.css";
import { AnimatePresence } from "framer-motion";
import Tab from "./Tab";
import { MDBContainer } from "mdb-react-ui-kit";

const branchID = new URL(document.location).searchParams.get("build") || "test";

console.log("branchID", branchID);

const socket = io("/", {
  query: {
    branchID,
  },
});
let logs = [];

function App() {
  const [status, setStatus] = useState("ready");
  const [details, setDetails] = useState([]);
  const [complete, setComplete] = useState(false);
  const [logView, setLogView] = useState(false);
  const [activeTab, setActiveTab] = useState({});

  useEffect(() => {
    socket.on("status", (status) => {
      setStatus(status);
    });
    socket.on("details", (details) => {
      setDetails(details);
    });
    socket.on("complete", () => setComplete(true));
    socket.on("log", (log) => {
      logs.push(log);
    });
  }, []);

  const props = {
    status,
    setStatus,
    details,
    setDetails,
    complete,
    setComplete,
    logView,
    setLogView,
    activeTab,
    setActiveTab,
    socket,
    logs,
  };

  return (
    <MDBContainer className="py-4">
      <div className="row">
        <div className="col-6">
          <StaticRouter location={status}>
            <AnimatePresence exitBeforeEnter>
              <Switch key={status}>
                <Route exact path=":id">
                  <Tab {...props} />
                </Route>
              </Switch>
            </AnimatePresence>
          </StaticRouter>
        </div>
        <div className="col-6">
          <h5 className="text-center m-0">Logs</h5>
          <hr />
          {logs.map((log) => (
            <p key={log}>{log}</p>
          ))}
        </div>
      </div>
    </MDBContainer>
  );
}

export default App;
